import { getApp } from 'firebase/app';
import {
  browserSessionPersistence,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth';
import { Spinner } from 'flowbite-react';
import { useFormik } from 'formik';
import { NextPage } from 'next';
import { AuthAction, withUser } from 'next-firebase-auth';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { setContext } from '../shared/api/apiService';
import { IFirebaseError } from '../shared/models/IAPIResponse';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(6).required('Password is required'),
});

const LoginPage: NextPage = () => {
  const router = useRouter();
  const auth = getAuth(getApp());
  const provider = new GoogleAuthProvider();
  const [errorWhenFormSubmit, setErrorWhenFormSubmit] =
    React.useState<IFirebaseError>({
      code: '',
      message: '',
      with: '',
      hasError: false,
    });
  const initialFormValues = {
    email: '',
    password: '',
  };
  const handleFormSubmit = async (values: any) => {
    console.log('values', values);
    await setPersistence(auth, browserSessionPersistence);
    try {
      nookies.set(null, 'isNewUser', String(false), {
        path: '/',
      });
      const userCredentials: UserCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      // if (userCredentials.user) {
      //   const token = await userCredentials.user.getIdToken();

      //   nookies.destroy(null, 'token');
      //   nookies.set(null, 'token', token, { path: '/' });
      //   router.push('/checking');
      // }
    } catch (error: any) {
      console.log('error', error);
      const firebaseError: IFirebaseError = {
        code: error.code,
        message: error.message,
        with: 'form',
        hasError: true,
      };
      setErrorWhenFormSubmit(firebaseError);
    }
  };
  useEffect(() => {
    (async () => {
      const userDetails = await getRedirectResult(auth);
      console.log('userDetails', userDetails);
      if (userDetails) {
        const additionaldata = getAdditionalUserInfo(userDetails!);

        nookies.set(null, 'isNewUser', String(additionaldata!.isNewUser), {
          path: '/',
        });
      }
    })();
  }, []);

  const handleResetForm = () => {};

  const signIn = async (auth: any, provider: any) => {
    await setPersistence(auth, browserSessionPersistence);
    try {
      nookies.set(null, 'isNewUser', String(false), {
        path: '/',
      });
      // await signInWithRedirect(auth, provider);
      await signInWithPopup(auth, new GoogleAuthProvider());
    } catch (error) {
      console.log('error', error);
    }
  };

  const loginForm = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: handleFormSubmit,
    onReset: handleResetForm,
  });

  return (
    <>
      <NextSeo
        title="Login | Bulk apply"
        description="Job Application Dashboard Login"
      />
      <div className="flex flex-col items-center justify-center min-h-screen bg-slate-50">
        <div className="w-full max-w-sm">
          <div className="px-8 pt-6 pb-8 mb-4 bg-white shadow-md rounded-xl">
            <img
              src="/images/ba-logo-black.webp"
              alt="Bulk Apply Logo"
              className="w-5/12 pb-4 mx-auto"
            />
            <h2 className="pb-2 text-xl font-bold text-center text-gray-700">
              Login
            </h2>
            <p className="pb-8 text-sm text-center text-gray-700">
              Login to get access to Bulk Apply Dashboard
            </p>

            {/* Google Sign In Button */}
            <div className="grid grid-cols-1 gap-3 ">
              <div>
                <button
                  type="button"
                  onClick={() => signIn(auth, provider)}
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="sr-only">Sign up with Google</span>
                  <img
                    className="w-5 h-5"
                    src="/icons/google.ico"
                    alt="Google logo"
                  />
                  <span className="ml-2">Continue with Google</span>
                </button>
              </div>
            </div>

            <div className="mt-6 mb-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-500 bg-white">Or</span>
                </div>
              </div>
            </div>
            {errorWhenFormSubmit.hasError &&
            errorWhenFormSubmit.with === 'form' ? (
              <div
                className="relative px-4 py-3 text-red-700 bg-red-100 border border-red-400 rounded"
                role="alert"
              >
                <strong className="font-bold">Error!&nbsp;</strong>
                <span className="block sm:inline">
                  {errorWhenFormSubmit.code === 'auth/user-not-found'
                    ? 'User not found'
                    : ''}
                  {errorWhenFormSubmit.code === 'auth/wrong-password'
                    ? 'Wrong password'
                    : ''}
                </span>
                <button
                  className="absolute top-0 bottom-0 right-0 px-4 py-3"
                  data-dismiss="alert"
                >
                  <svg
                    className="w-6 h-6 text-red-500 fill-current"
                    role="button"
                    viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path d="M14.348 5.652a.999.999 0 1 0-1.414 1.414L11 7.414l-1.934 1.934a.999.999 0 1 0 1.414 1.414L12.414 9l1.934 1.934a.999.999 0 1 0 1.414-1.414L13.828 7.586l1.52-1.52z" />
                    <path d="M5.652 14.348a.999.999 0 1 0 1.414-1.414L9 12.586l1.934-1.934a.999.999 0 1 0-1.414-1.414L7.586 11l-1.934-1.934a.999.999 0 1 0-1.414 1.414L6.172 12.414l-1.52 1.52z" />
                  </svg>
                </button>
              </div>
            ) : null}

            <form onSubmit={loginForm.handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.email}
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
                {loginForm.touched.email && loginForm.errors.email ? (
                  <div className="text-xs italic text-red-500">
                    {loginForm.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.password}
                  className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
                {loginForm.touched.password && loginForm.errors.password ? (
                  <div className="text-xs italic text-red-500">
                    {loginForm.errors.password}
                  </div>
                ) : null}
                <a
                  href="/reset"
                  className="text-sm text-blue-500 align-top hover:text-blue-800"
                >
                  forgot password?
                </a>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="flex items-center justify-center w-full px-4 py-2 font-bold text-white rounded bg-sky-700 hover:bg-sky-900 focus:outline-none focus:shadow-outline"
                >
                  Continue
                </button>
              </div>
              <div className="mt-4 text-center">
                <span className="text-sm text-gray-700">
                  Don't have an account?
                </span>{' '}
                <a
                  href="/signup"
                  className="text-sm text-blue-500 hover:text-blue-800"
                >
                  Create one
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export async function getServerSideProps(context: any): Promise<any> {
  setContext(context);

  return {
    props: {} as never,
  };
}
export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: () => (
    <div className="flex items-center justify-center h-screen">
      <Spinner aria-label="Loading..." size="xl" />
    </div>
  ),
})(LoginPage);
